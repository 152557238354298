'use client';
import Link from 'next/link';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useSetting } from '@/providers/SettingsProvider';
import { IAnnouncement } from '@/types/announcements';

import 'swiper/css';
import 'swiper/css/autoplay';

interface Props {
    announcements: IAnnouncement[];
}

const AnnouncementBar = ({ announcements }: Props) => {
    const settings = useSetting();
    return (
        <div className="announcement-bar @container">
            <div className="flex items-center justify-center w-full h-10 p-8 bg-black opacity-8">
                <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation={true}
                    autoplay={{
                        delay: parseInt(settings.site.swiper_delay) * 1000,
                    }}
                    modules={[Autoplay]}
                    className="announcement-slider"
                >
                    {announcements.map((announcement) => (
                        <SwiperSlide key={announcement.id}>
                            <p className="text-center">
                                <span className="block tracking-widest text-white uppercase lg:inline">
                                    {announcement.title}
                                </span>
                                <Link
                                    target="_blank"
                                    aria-label={announcement.title}
                                    href={announcement.link}
                                    className="block ml-2 tracking-wider text-gray-300 underline underline-offset-4 lg:inline"
                                >
                                    {announcement.link_text}
                                </Link>
                            </p>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
};

export default AnnouncementBar;
