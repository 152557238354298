'use client';

import { useState, useEffect } from 'react';

const useDetectDevice = () => {
    const [device, setDevice] = useState('');

    useEffect(() => {
        const handleDeviceDetection = () => {
            const userAgent = navigator.userAgent.toLowerCase();
            const isMobile =
                /iphone|ipad|ipod|android|blackberry|windows phone/g.test(
                    userAgent
                );
            const isTablet =
                /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(
                    userAgent
                );
            const isDesktop = !isMobile && !isTablet;

            if (isMobile) {
                setDevice('Mobile');
            } else if (isTablet) {
                setDevice('Tablet');
            } else if (isDesktop) {
                setDevice('Desktop');
            }
        };

        // Initial device detection
        handleDeviceDetection();

        // Recheck on resize or orientation change
        window.addEventListener('resize', handleDeviceDetection);
        window.addEventListener('orientationchange', handleDeviceDetection);

        // Clean up on component unmount
        return () => {
            window.removeEventListener('resize', handleDeviceDetection);
            window.removeEventListener(
                'orientationchange',
                handleDeviceDetection
            );
        };
    }, []);

    return device;
};

export default useDetectDevice;
