'use client';

import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import {
    FaFacebookF,
    FaTwitter,
    FaFacebookMessenger,
    FaWhatsapp,
} from 'react-icons/fa';

import useDetectDevice from '@/hooks/useDetectDevice';

const ShareButton = () => {
    const pathname = usePathname();
    const device = useDetectDevice();
    const [currentUrl, setCurrentUrl] = useState('https://evasartnepal.com');

    useEffect(() => {
        setCurrentUrl('https://evasartnepal.com' + pathname);
    }, [pathname]);
    return (
        <div className="fixed right-0 z-10 space-y-2 transform -translate-y-1/2 top-1/2">
            <a
                aria-label="Share on facebook"
                href={`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center w-12 h-12 text-white bg-blue-600 rounded-l-full shadow-lg hover:bg-blue-700"
            >
                <FaFacebookF size={20} />
            </a>
            <a
                aria-label="Share on Twitter"
                href={`https://twitter.com/intent/tweet?url=${currentUrl}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center w-12 h-12 text-white bg-blue-400 rounded-l-full shadow-lg hover:bg-blue-500"
            >
                <FaTwitter size={20} />
            </a>
            {['Mobile', 'Tablet'].includes(device) && (
                <>
                    <a
                        aria-label="Share on Messenger"
                        href={`fb-messenger://share?link=${currentUrl}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center justify-center w-12 h-12 text-white bg-blue-500 rounded-l-full shadow-lg hover:bg-blue-600"
                    >
                        <FaFacebookMessenger size={20} />
                    </a>
                    <a
                        aria-label="Share on Whatsapp"
                        href={`https://wa.me/?text=${currentUrl}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center justify-center w-12 h-12 text-white bg-green-500 rounded-l-full shadow-lg hover:bg-green-600"
                    >
                        <FaWhatsapp size={20} />
                    </a>
                </>
            )}
        </div>
    );
};

export default ShareButton;
